.card {
	-webkit-backdrop-filter: blur(18px);
	backdrop-filter: blur(18px);
	background-image: -webkit-linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.6),
		rgba(255, 255, 255, 0.6)
	);
	background-image: linear-gradient(
		45deg,
		rgba(255, 255, 255, 0.6),
		rgba(255, 255, 255, 0.6)
	);
}
