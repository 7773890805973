.home {
	width: 100%;
	max-width: 1200px;
	display: grid;
	grid-template-columns: repeat(
		auto-fill,
		minmax(120px, 1fr) minmax(120px, 1fr)
	);

	@apply m-2 p-2;
	.header {
		@apply flex gap-2 p-2;
		grid-column: 1 / -1;
	}

	.education {
		grid-column: 1 / 3;
	}

	.experience {
		grid-column: 3 / -1;
	}

	.skills {
		grid-column: 1 / -3;
	}

	.projects {
		grid-column: -3 / -1;
	}
}

@media screen and (max-width: 768px) {
	.home {
		grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
		.header {
			grid-column: 1 / -1;
		}

		.education {
			grid-column: 1 / -1;
		}

		.experience {
			grid-column: 1 / -1;
		}

		.skills {
			grid-column: 1 / -1;
		}

		.projects {
			grid-column: 1 / -1;
		}
	}
}
